import { AudienceEnum, LandingPages } from 'src/enums'
import { useDataFlowPageEnabled, useFeatureForAudience } from '.'

import { ConditionalFeaturesList } from 'src/features'
import { FeatureFlag } from 'src/services/graphapi'
import { useEcsConfig } from 'src/queries'

export const useConditionalPageFeaturesList = (): ConditionalFeaturesList => {
  const { data: configFlags } = useEcsConfig()
  const skusMappingReportFlagEnabled = configFlags?.skusMappingReportFlag === FeatureFlag.Enabled
  return {
    [LandingPages.SkuMappingReport]: skusMappingReportFlagEnabled,
    [LandingPages.CloudParityReport]: useFeatureForAudience(AudienceEnum.PRODUCTION),
    [LandingPages.DataFlows]: useDataFlowPageEnabled(),
    [LandingPages.AIML]: useFeatureForAudience(AudienceEnum.PRODUCTION),
    [LandingPages.RTI]: useFeatureForAudience(AudienceEnum.PRODUCTION),
    [LandingPages.SGIOverview]: useFeatureForAudience(AudienceEnum.DEV),
    [LandingPages.SGIAppView]: useFeatureForAudience(AudienceEnum.DEV),
    [LandingPages.CardinalityView]: useFeatureForAudience(AudienceEnum.DEV),
    [LandingPages.Models]: useFeatureForAudience(AudienceEnum.DEV),
    [LandingPages.ServiceModels]: useFeatureForAudience(AudienceEnum.PRODUCTION),
  }
}
